import React, { useRef, useState } from "react";

import Language from "../helpers/language";


//Define the admin header
export const SelectItems = props => {
    const { selected, items, title, searchTitle, hideSearch, hideAll, onChange } = props

    let st = Language.getTitleCase('search')
    if ( searchTitle != undefined && searchTitle != null ) {
        st = Language.getTitleCase(searchTitle)
    }

    const [state, setState] = useState({
        drag: useRef(),
        search: '',
    })
    const { search, drag } = state

    //set a default ref?
    if ( drag.current == null ) {
        drag.current = {src: null, dst: null}
    }

    const lc_search = search.toLowerCase()
    const entries = (search != "")? items.filter(x => x.name.toLowerCase().indexOf(lc_search) >= 0): items

    const handleChange = (e) => {
        setState(prev => ({...prev, [e.target.name]: e.target.value }))
    }

    const handleSelectAll = () => {
        onChange( entries.map( x => x.uid ))
    }

    const handleUnselectAll = () => {
        onChange( [] )
    }

    const handleAddSelected = (uid) => {
        onChange( selected.concat( [uid] ))
    }

    const handleRemoveSelected = (uid) => {
        onChange( selected.filter(x => x != uid) )
    }

    const handleDragStart = (e) => {
        drag.current.src = e.target.id
    }

    const handleDragAllow = (e) => {
        drag.current.dst = e.target.id
        e.preventDefault()
    }

    const handleDragDrop = (e) => {
        const { src, dst } = drag.current

        //Can we do a DnD?
        const s_idx = selected.indexOf( src )
        if ( dst != null && src != null && s_idx >= 0 ) {
            const uid = selected[s_idx]
            selected.splice( s_idx, 1 )

            //Find where the target lives, if not found, Append
            const t_idx = selected.indexOf( dst )
            if ( t_idx >= 0 ) {
                selected.splice(t_idx, 0, uid)
            }
            else {
                selected.push( uid )
            }
        }

        //Reset my DnD state
        drag.current = { src: null, dst: null }
        onChange( selected )
    }

    //Setup my lookups
    let lookup = {}
    entries.forEach( x => {
        lookup[x.uid] = x
    })

    let sel_lookup = {}
    selected.forEach( x => {
        sel_lookup[x] = true
    })

    //Filter down my dataset
    const sel = selected.filter(x => x in lookup).map( x => lookup[x] )
    const unsel = entries.filter(x => !(x.uid in sel_lookup))

    return (
        <div className='create-group'>
            <div className='central__filters'>
            {!hideSearch &&
                <div className='central__head-search-wrapper'>
                    <svg
                        width='15'
                        height='16'
                        viewBox='0 0 15 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M14.8828 14.6152L11.3379 11.0703C11.25 11.0117 11.1621 10.9531 11.0742 10.9531H10.6934C11.6016 9.89844 12.1875 8.49219 12.1875 6.96875C12.1875 3.62891 9.43359 0.875 6.09375 0.875C2.72461 0.875 0 3.62891 0 6.96875C0 10.3379 2.72461 13.0625 6.09375 13.0625C7.61719 13.0625 8.99414 12.5059 10.0781 11.5977V11.9785C10.0781 12.0664 10.1074 12.1543 10.166 12.2422L13.7109 15.7871C13.8574 15.9336 14.0918 15.9336 14.209 15.7871L14.8828 15.1133C15.0293 14.9961 15.0293 14.7617 14.8828 14.6152ZM6.09375 11.6562C3.48633 11.6562 1.40625 9.57617 1.40625 6.96875C1.40625 4.39062 3.48633 2.28125 6.09375 2.28125C8.67188 2.28125 10.7812 4.39062 10.7812 6.96875C10.7812 9.57617 8.67188 11.6562 6.09375 11.6562Z'
                            fill='currentColor'></path>
                    </svg>
                    <input
                        className='central__head-search rounded-md border-gray-200 bg-white text-gray-900 placeholder-text-gray-400 focus:border-drip7 focus:ring-0 sm:text-sm sm:leading-6'
                        type='search'
                        placeholder={st}
                        name='search'
                        value={search}
                        onChange={handleChange}
                    />
                </div>
            }

                {props.children}
            </div>

            <div className='create-group__users-container'>
                <div className='create-group__users'>
                    <h5>
                        {Language.getTitleCase('selected')}
                        &nbsp;
                        {Language.getTitleCase(title)} {Object.keys(sel).length}
                        {!hideAll && <>
                            &nbsp; ( <a onClick={handleUnselectAll} href="#">{Language.getSentenceCase('all')}</a> )
                        </>}
                    </h5>
                    <ul 
                    className='create-group__users-list ' 
                    tabIndex='0'
                    >
                        {Object.entries(sel).map( ([key, item]) => (
                            <li
                                tabIndex='0'
                                className='create-group__users-item'
                                key={"selected_" + key}
                                id={item.uid}
                                draggable={search == ""}
                                onDragStart={handleDragStart}
                                onDragEnter={handleDragAllow}
                                onDragOver={handleDragAllow}
                                onDragEnd={handleDragDrop}
                                onClick={() => handleRemoveSelected( item.uid )}>
                                {item.name}
                            </li>
                        ))}
                        <li
                            className='create-group__users-item'
                            key={"selected_-1"}
                            id={""}
                            draggable={search == ""}
                            onDragEnter={handleDragAllow}
                            onDragOver={handleDragAllow}
                            onDragEnd={handleDragDrop}>
                            &nbsp;
                        </li>
                    </ul>
                </div>

                <svg
                    aria-hidden='true'
                    focusable='false'
                    data-prefix='fas'
                    data-icon='exchange-alt'
                    className='svg-inline--fa fa-exchange-alt fa-w-16'
                    role='img'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 512 512'>
                    <path
                        fill='currentColor'
                        d='M0 168v-16c0-13.255 10.745-24 24-24h360V80c0-21.367 25.899-32.042 40.971-16.971l80 80c9.372 9.373 9.372 24.569 0 33.941l-80 80C409.956 271.982 384 261.456 384 240v-48H24c-13.255 0-24-10.745-24-24zm488 152H128v-48c0-21.314-25.862-32.08-40.971-16.971l-80 80c-9.372 9.373-9.372 24.569 0 33.941l80 80C102.057 463.997 128 453.437 128 432v-48h360c13.255 0 24-10.745 24-24v-16c0-13.255-10.745-24-24-24z'></path>
                </svg>

                <div className='create-group__users'>
                    <h5>
                        {Language.getTitleCase('unselected')}
                        &nbsp;
                        {Language.getTitleCase(title)}
                        {!hideAll && <>
                            &nbsp; ( <a onClick={handleSelectAll} href="#">{Language.getSentenceCase('all')}</a> )
                        </> }
                    </h5>
                    <ul 
                    className='create-group__users-list'
                     tabIndex='0'
                     >
                        {Object.entries(unsel).map( ([key, item]) => (
                            <li
                                tabIndex='0'
                                className='create-group__users-item'
                                key={"selected_" + key}
                                onClick={() => handleAddSelected( item.uid ) }>
                                {item.name}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};
