import React from "react";
import { useStore } from "../../store";
import Util from "../../helpers/util";
import Language from "../../helpers/language";

const withStore = BaseComponent => props => {
    const { usr_info } = useStore(state => ({ usr_info: state.usr_info }));
    return (
        <BaseComponent
            {...props}
            usr_info={usr_info}
            fileRef={React.useRef()}
        />
    );
};

class Klass extends React.Component {
    constructor(props) {
        super(props);

        let { group } = props;
        if (group == true || group == null || group == undefined) {
            group = { name: "", leaderboard_filter: true, user_count: "" };
        }

        this.state = {
            search: "",
            group,
            users: [],
            selected_users: {}
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleAddSelected = this.handleAddSelected.bind(this);
        this.handleRemoveSelected = this.handleRemoveSelected.bind(this);
    }

    componentDidMount() {
        const { tenant, showToast } = this.props;
        const { group, language } = this.state;

        //Download all users for the tenant
        Util.fetch_js( "/human/list/", { tenant_uid: tenant.uid },
            js => {
                this.setState({ group, users: js.users });
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );

        //Pull the details for the group
        if ("uid" in group) {
            Util.fetch_js(
                "/group/detail/",
                { group_uid: group.uid },
                js => {
                    let { group, users } = js;

                    let selected_users = {};
                    for (let i = 0; i < users.length; i++) {
                        selected_users[users[i].uid] = true;
                    }

                    this.setState({ group, selected_users });
                },
                (reason, code) => {
                    showToast(reason, 'failure');
                }
            );
        }
    }

    filterUsers(search, users) {
        //Do nothing
        if (search == "") {
            return users;
        }

        //Only keep
        let result = [];
        const search_lc = search.toLowerCase();
        for (let i = 0; i < users.length; i++) {
            const user = users[i];
            if (user.name.toLowerCase().indexOf(search_lc) >= 0) {
                result.push(user);
            }
        }

        return result;
    }

    handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        let { group } = this.state;
        group[name] = value;

        this.setState({ group });
    }

    handleChecked(event) {
        const name = event.target.name;
        const checked = event.target.checked;

        let { group } = this.state;
        group[name] = checked;

        this.setState({ group });
    }

    handleSave() {
        const { showToast, onClose } = this.props;
        const { group, selected_users } = this.state;

        let payload = {
            group_uid: group.uid,
            name: group.name,
            leaderboard_filter: group.leaderboard_filter,
            user_uids: Object.keys(selected_users)
        };

        Util.fetch_js(
            "/group/modify/",
            payload,
            js => {
                showToast(Language.getSentenceCase('updated'), 'successful');
                onClose(true);
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );
    }

    handleAddSelected(uid) {
        let { selected_users } = this.state;
        selected_users[uid] = true;

        this.setState({ selected_users });
    }

    handleRemoveSelected(uid) {
        let { selected_users } = this.state;
        delete selected_users[uid];

        this.setState({ selected_users });
    }

    render() {
        const { tenant, showToast, onClose } = this.props;
        const { search, group, users, selected_users } = this.state;

        const filtered_users = this.filterUsers(search, users);

        console.log(selected_users);

        let selected_group = [];
        let unselected_group = [];
        for (let i = 0; i < filtered_users.length; i++) {
            if (filtered_users[i].uid in selected_users) {
                selected_group.push(filtered_users[i]);
            }
            else {
                unselected_group.push(filtered_users[i]);
            }
        }

        return (
            <div className='basic-modal basic-modal--add-group'>
                <div className='basic-modal__head'>
                    <h2>{Language.getTitleCase('groups')}</h2>
                    <svg
                        className='basic-modal__close-button'
                        width='12'
                        height='11'
                        viewBox='0 0 12 11'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        onClick={onClose}>
                        <path
                            d='M7.93359 5.375L10.8633 2.44531C11.2441 2.09375 11.2441 1.50781 10.8633 1.15625L10.2188 0.511719C9.86719 0.130859 9.28125 0.130859 8.92969 0.511719L6 3.44141L3.04102 0.511719C2.68945 0.130859 2.10352 0.130859 1.75195 0.511719L1.10742 1.15625C0.726562 1.50781 0.726562 2.09375 1.10742 2.44531L4.03711 5.375L1.10742 8.33398C0.726562 8.68555 0.726562 9.27148 1.10742 9.62305L1.75195 10.2676C2.10352 10.6484 2.68945 10.6484 3.04102 10.2676L6 7.33789L8.92969 10.2676C9.28125 10.6484 9.86719 10.6484 10.2188 10.2676L10.8633 9.62305C11.2441 9.27148 11.2441 8.68555 10.8633 8.33398L7.93359 5.375Z'
                            fill='currentColor'></path>
                    </svg>
                </div>

                <div className='basic-modal__field-container basic-modal__field-container--add-group'>
                    <div className='basic-modal__field basic-modal__field--add-group'>
                        <label
                            className='basic-modal__label'
                            htmlFor='newQuestionCategory'>
                            {Language.getTitleCase('name')}
                        </label>
                        <input
                            type='text'
                            className='basic-modal__input'
                            id='newQuestionCategory'
                            placeholder={Language.getSentenceCase('group name')}
                            name='name'
                            value={group.name}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className='basic-modal__field basic-modal__field--add-group'></div>
                </div>

                <div className='customization-checkbox'>
                    <input
                        type='checkbox'
                        aria-label="checkbox"
                        name='leaderboard_filter'
                        onChange={this.handleChecked}
                        id='leaderboard_filter'
                        checked={group.leaderboard_filter}
                    />
                    <span className='checkmark'></span>
                    <span className='tool-tip__container'>
                        <span className="tool-tip__text">
                            {Language.getSentenceCase("when checked, this group will appear in the leadearboard filter")}
                        </span>
                    <label htmlFor='can_register'>
                        {Language.getTitleCase('Filter on Leaderboard')}
                    </label>
                    </span>
                </div>

                <div className='create-group'>
                    <div className='central__head-search-wrapper'>
                        <svg
                            width='15'
                            height='16'
                            viewBox='0 0 15 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M14.8828 14.6152L11.3379 11.0703C11.25 11.0117 11.1621 10.9531 11.0742 10.9531H10.6934C11.6016 9.89844 12.1875 8.49219 12.1875 6.96875C12.1875 3.62891 9.43359 0.875 6.09375 0.875C2.72461 0.875 0 3.62891 0 6.96875C0 10.3379 2.72461 13.0625 6.09375 13.0625C7.61719 13.0625 8.99414 12.5059 10.0781 11.5977V11.9785C10.0781 12.0664 10.1074 12.1543 10.166 12.2422L13.7109 15.7871C13.8574 15.9336 14.0918 15.9336 14.209 15.7871L14.8828 15.1133C15.0293 14.9961 15.0293 14.7617 14.8828 14.6152ZM6.09375 11.6562C3.48633 11.6562 1.40625 9.57617 1.40625 6.96875C1.40625 4.39062 3.48633 2.28125 6.09375 2.28125C8.67188 2.28125 10.7812 4.39062 10.7812 6.96875C10.7812 9.57617 8.67188 11.6562 6.09375 11.6562Z'
                                fill='currentColor'></path>
                        </svg>
                        <input
                            className='central__head-search rounded-md border-gray-200 bg-white text-gray-900 placeholder-text-gray-400 focus:border-drip7 focus:ring-0 sm:text-sm sm:leading-6'
                            type='search'
                            name='search'
                            placeholder={Language.getSentenceCase('Search by name, email, etc.')}
                            value={search}
                            onChange={e =>
                                this.setState({ search: e.target.value })
                            }
                        />
                    </div>
                    <p>{Language.getSentenceCase('on clicking the buttons it will move to selected users or unselected user')}</p>
                    <div className='create-group__users-container'>
                        <div className='create-group__users'>
                            <h3>{Language.getTitleCase('selected users')}</h3>
                            <ul className='create-group__users-list' tabIndex='0' aria-live='polite'>
                                {Object.entries(selected_group).map( ([key, user]) => (
                                        <li
                                            tabIndex='0'
                                            className='create-group__users-item'
                                            key={"selected_users_" + key}
                                            onClick={() =>
                                                this.handleRemoveSelected(
                                                    user.uid
                                                )
                                            }>
                                            {user.name}
                                        </li>
                                    )
                                )}
                            </ul>
                        </div>

                        <svg
                            aria-hidden='true'
                            focusable='false'
                            data-prefix='fas'
                            data-icon='exchange-alt'
                            className='svg-inline--fa fa-exchange-alt fa-w-16'
                            role='img'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 512 512'>
                            <path
                                fill='currentColor'
                                d='M0 168v-16c0-13.255 10.745-24 24-24h360V80c0-21.367 25.899-32.042 40.971-16.971l80 80c9.372 9.373 9.372 24.569 0 33.941l-80 80C409.956 271.982 384 261.456 384 240v-48H24c-13.255 0-24-10.745-24-24zm488 152H128v-48c0-21.314-25.862-32.08-40.971-16.971l-80 80c-9.372 9.373-9.372 24.569 0 33.941l80 80C102.057 463.997 128 453.437 128 432v-48h360c13.255 0 24-10.745 24-24v-16c0-13.255-10.745-24-24-24z'></path>
                        </svg>

                        <div className='create-group__users'>
                            <h3>{Language.getTitleCase('unselected users')}</h3>
                            <ul className='create-group__users-list' tabIndex='0' aria-live='polite'>
                                {Object.entries(unselected_group).map(
                                    ([key, user]) => (
                                        <li
                                            tabIndex='0'
                                            className='create-group__users-item'
                                            key={"selected_users_" + key}
                                            onClick={() =>
                                                this.handleAddSelected(user.uid)
                                            }>
                                            {user.name}
                                        </li>
                                    )
                                )}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='basic-modal__button-container'>
                    <button
                        className='basic-modal__button basic-modal__button--clear'
                        onClick={onClose}>
                        {Language.getTitleCase('cancel')}
                    </button>
                    <button
                        className='basic-modal__button basic-modal__button--solid'
                        onClick={this.handleSave}>
                        {Language.getTitleCase('save')}
                    </button>
                </div>
            </div>
        );
    }
}

export const GroupModal = withStore(Klass);
